
export default class GTMCallback {
  
  timesCalled : number = 0
  callback : Function
  args : Array<any>
  context : any
  
  constructor (callback : Function, context : any, args : Array<any>) {
    this.context = context
    this.callback = callback
    this.args = args
  }

  exec() {
    if (this.timesCalled === 0) {
      this.callback.apply(this.context, this.args);
    }
    this.timesCalled += 1
  }
}