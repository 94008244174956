declare global {
  interface Window { dataLayer: Array<object> }
}

export default class DelayedDataLayer {  

  static get data () {
    let data = JSON.parse(localStorage.getItem('delayedDataLayer') || "[]")
    let dataArray : Array<Object>
    
    if ( !Array.isArray(data) ) {
      dataArray = []
    } else {
      dataArray = data
    }

    return new Proxy(dataArray, {
      set: (target, property, value, receiver) : boolean => {
        target[property] = value

        localStorage.setItem('delayedDataLayer', JSON.stringify(target))

        return true
      },
      get: function(target, property) : any {
        return target[property]
      }
    })
  }

  static push (object: Object) : void {
    this.data.push(object)
  }

  static pushToDatalayer () : void {
    if (typeof window.dataLayer === 'undefined') {
      window.dataLayer = []
    };

    this.data.forEach(d => {
      window.dataLayer.push(d);
    })
    this.data.length = 0
  }

}