import React from "react";
import CookieDialog from "./CookieDialog";


const CookieConsentDialog = ({
    showCookieDialog,
    userCountry
} : {
    showCookieDialog?: boolean,
    userCountry?: string
}) => {
    return (
        <CookieDialog showCookieDialog={showCookieDialog} userCountry={userCountry} />
    );
};

export default CookieConsentDialog;