import TranslationsHandler from "../components/TranslationsHandler"
import DelayedDataLayer from "../scripts/DelayedDataLayer"
import EECT from "../scripts/EnhancedEcommTracking"
import GTMCallback from "../scripts/GTMCallback"

declare global {
    interface Window { 
        TranslationsHandler: TranslationsHandler,
        DelayedDataLayer: DelayedDataLayer,
        EECT : EECT,
        GTMCallback : any,
    }
}

window.TranslationsHandler = TranslationsHandler

//Expose DelayedDataLayer so i can be reached from DOM and Other scripts. 
window.DelayedDataLayer = DelayedDataLayer
window.EECT = EECT
window.GTMCallback = GTMCallback

DelayedDataLayer.pushToDatalayer() //Push any objects to GTM datalayer
