import React from "react";
import TranslationsHandler from "./TranslationsHandler";

interface IStateProps {
    locale?: string
}
interface IProps {
    path: string
    interpolation?: {[key: string]: (() => string | number) | (string | number)}
    lokalise?: boolean
    category?: boolean
    date?: Date
    maxWidows?: number
}

type IComponentProps = IProps & IStateProps

interface IState {
    translation: string
    missing: boolean
    blank: boolean
}

export class Translate extends React.Component<IComponentProps, IState> {
    private dirty: boolean = true
    private root = React.createRef<HTMLSpanElement>()

    constructor (props: IProps) {
        super(props)
        this.state = {
            translation: "",
            missing: false,
            blank: false,
        }
    }

    get path() {
        if (this.props.lokalise) {
            return "lokalise." + this.props.path
        } else {
            return this.props.path
        }
    }

    static path (path: string, interpolation? : {[key: string]: (() => string | number) | (string | number)}, lokalise = false) {
        path = lokalise ? "lokalise." + path : path

        let translation = TranslationsHandler.get(path)
        if (interpolation && translation) {
            for (let [key, value] of Object.entries(interpolation)) {
                translation = translation.replace(`%{${key}}`, String(value))
                translation = translation.replace(`{{${key}}}`, String(value))
            }
        }
        return translation
    }

    static exists (path: string, lokalise = false) {
        path = lokalise ? "lokalise." + path : path
        return TranslationsHandler.exists(path)
    }

    getTranslation () {
        let translation = TranslationsHandler.get(this.path)        
        let missing = false
        let blank = false

        if (this.props.interpolation && translation) {
            for (let [key, value] of Object.entries(this.props.interpolation)) {
                translation = translation.replace(`%{${key}}`, String(value))
                translation = translation.replace(`{{${key}}}`, String(value))
            }
        }
        
        this.setState({
            translation: translation,
            missing: missing,
            blank: blank
        })
    }

    get translation () {
        if (this.props.maxWidows) {
            const fragments = this.state.translation.split(" ")
            const string = fragments.reduce((acc, string, index) => {
                if (fragments.length - index <= this.props.maxWidows - 1) {
                    return acc + "&nbsp;" + string
                } else {
                    return acc + " " + string
                }
            })
            return string
        } else {
            return this.state.translation
        }
        

    }
    get missing () {
        return this.state.missing
    }
    get blank () {
        return this.state.blank
    }
    
    render() {
        return (<span ref={this.root} dangerouslySetInnerHTML={{__html: this.translation}}></span>)
    }

    UNSAFE_componentWillReceiveProps (props: IProps) {
        this.getTranslation()
    }

    componentDidMount () {
        this.getTranslation()
    }
}


export default Translate