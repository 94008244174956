import React from "react";
import styled from "styled-components"
import { EColor, EIconGlyph, ESize } from "../../styleConstants";
import { breakPoint, rem, useIsMobile } from "../styleUtils";
import Icon from "./icon";

const CheckboxElement = styled.div<{
    mobileLayout: boolean,
    disabled: boolean
}>`
    height: ${rem(40)};
    width: ${rem(40)};
    opacity: ${props => props.disabled ? "0.5" : "1"};

    input[type="checkbox"] {
        vertical-align: middle;
        height: 30px;
        width: 30px;
        -webkit-appearance: none;
        background: url('https://dnjpbxg2wcpr8.cloudfront.net/web/icons/list-check-small.svg');
        background-size: 130%;
        background-position: 1000px 1000px;
        background-repeat: no-repeat;
        background-size: 130%;
        border-radius: 5px;
        border: 1px solid #ccc;
        display: inline-block;
    }
    input[type="checkbox"]:checked {
        background-position: center center;
    }
    label {
        vertical-align: text-top;
        margin-left: 10px;
    }
`

const toggleCheckbox = (checked: boolean, stateHook: React.Dispatch<React.SetStateAction<boolean>>) => {
    stateHook(!checked);
}

/**
 * Checkbox component.
 * @param checked - default checked value 
 * @param disabled - disable togglability 
 * @param stateHook - statehook to pass to checkbox 
 * @returns Checkbox component
 */
export default function Checkbox ({
    checked,
    disabled,
    stateHook
}: {
    checked: boolean,
    disabled?: boolean | false,
    stateHook?: React.Dispatch<React.SetStateAction<boolean>>
}) {
    const isMobile = useIsMobile();
    
    return (
        <CheckboxElement mobileLayout={isMobile} disabled={disabled}>
            <input
                type="checkbox"
                disabled={disabled}
                defaultChecked={checked}
                onChange={() => toggleCheckbox(checked, stateHook)}
            />
        </CheckboxElement>
    )
}