import React from "react";
import styled from "styled-components"
import { EColor, EIconGlyph, ESize } from "../../styleConstants";
import { breakPoint, rem } from "../styleUtils";
import Icon from "./icon";



type ButtonContentProps = {
    alignment: "left"|"center"
}
const ButtonContent = styled.div<ButtonContentProps>`
    flex: 1;
    text-align: ${props => props.alignment};
    
`
const ButtonIcon = styled.div`
    flex: 0;
    display: flex;
    justify-content: center;
    align-items: center;
`

const ButtonSpinner = styled.div`
    @keyframes spin {
        from {
            transform:rotate(0deg);
        }
        to {
            transform:rotate(360deg);
        }
    }

    animation-name: spin;
    animation-duration: 1000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear; 

    width: ${rem(36)};
    height: ${rem(36)};

    & > svg {
        width: 100%;
        height: 100%;
    }
`

type ButtonProps = {
    buttonType: "primary"|"secondary"|"tertiary"
    showSpinner: boolean
    iconOnly: boolean
    margin: ESize
    colorOverride?: EColor
    backgroundOverride?: EColor
    border?: EColor | undefined
    thin?: boolean
}

const ButtonElement = styled.button<ButtonProps>`
    display: flex;
    touch-action: manipulation;
    
    min-width: ${props => props.buttonType === "tertiary" || props.iconOnly ? rem(0) : rem(328)};

    align-items: center;
    border-radius: ${rem(8)};
    border: 0px;
    margin-bottom: ${props => rem(props.margin)};
    justify-content: ${props => props.showSpinner ? "center" : "flex-start"};
    
    ${(props) => {
        switch (props.buttonType) {
            case "primary":
                return `
                    background: ${EColor.SunglowYellow};
                    padding: ${rem(ESize.default)};
                    color: ${EColor.Russian};
                    font-size: ${props.thin ? rem(20) : rem(22)};
                    height: ${props.thin ? rem(56) : rem(72)};
                    width: ${props.iconOnly ? rem(72) : "auto"};
                    font-weight: 500;
                    @media only screen and (max-width : 768px) {
                        min-width: 100%
                    }
                    
                `
            case "secondary":
                return `
                    background: ${EColor.Russian};
                    padding: ${rem(ESize.default)};
                    color: ${EColor.FloralWhite};
                    font-size: ${props.thin ? rem(20) : rem(22)};
                    height: ${props.thin ? rem(56) : rem(72)};
                    width: ${props.iconOnly ? rem(72) : "auto"};
                    @media only screen and (max-width : 768px) {
                        min-width: 100%
                    }
                `
            case "tertiary":
                return `
                    background: ${EColor.ColombianBlue};
                    padding: ${rem(13)};
                    color: ${EColor.FloralWhite};
                    font-size: ${rem(14)};
                    height: ${rem(40)};
                    width: ${props.iconOnly ? rem(40) : "auto"};
                    flex-flow: row-reverse;
                    & > ${ButtonIcon} {
                        margin-right: ${props.iconOnly ? rem(0) : rem(11)};
                    }
                    font-weight: 700;
                `
        }
    }};


    ${(props) => props.colorOverride ? `color: ${props.colorOverride};` : ``}
    ${(props) => props.backgroundOverride ? `background: ${props.backgroundOverride};` : ``}
    ${(props) => props.border ? `box-shadow: inset 0px 0px 0px ${rem(1)} ${props.border};` : ``}

`



export default function Button ({
    type,
    label,
    icon,
    margin = ESize.small,
    loading = false,
    colorOverrided,
    backgroundOverride,
    border = undefined,
    submitButton = false,
    showSpinner = false,
    thin = false,
}: {
    type: "primary"|"secondary"|"tertiary"
    label?: JSX.Element|string
    icon?: EIconGlyph
    margin?: ESize
    loading?: boolean
    colorOverrided?: EColor
    backgroundOverride?: EColor
    border?: EColor | undefined
    submitButton?: boolean
    showSpinner?: boolean
    thin?: boolean
}) {

    let color = type === "primary" ? EColor.Russian : EColor.FloralWhite
        color = colorOverrided ? colorOverrided : color

    return (
        <ButtonElement 
            type={submitButton ? "submit" : "button"} 
            buttonType={type} 
            iconOnly={icon && !label} 
            margin={margin} 
            showSpinner={showSpinner}
            colorOverride={colorOverrided}
            backgroundOverride={backgroundOverride}
            border={border}
            thin={thin}
            >
                {showSpinner && 
                    <ButtonSpinner>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill={`${EColor.Russian}`} fillOpacity="0.1" fillRule="evenodd" clipRule="evenodd" d="M12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1ZM12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3Z"/>
                            <path fill={`${EColor.Russian}`} d="M23 12C23 5.92487 18.0751 1 12 1V3C16.9706 3 21 7.02944 21 12H23Z"/>
                        </svg>
                    </ButtonSpinner>
                }
                {!showSpinner && label &&
                    <ButtonContent alignment={icon ? "left" : "center"}>
                        {label}
                    </ButtonContent>
                }
                {!showSpinner && icon && 
                    <ButtonIcon>
                        <Icon icon={icon} size={type === "tertiary" ? ESize.small : ESize.default} color={color}></Icon>
                    </ButtonIcon>
                }

        </ButtonElement>
    )
}