import DelayedDataLayer from "../scripts/DelayedDataLayer"

declare global {
  interface Window { dataLayer: Array<object> }
}

interface EECTCartItem {
  name : string
  category : string
  quantity : number
}

interface EECTProduct {
  name : string
  category : string
  list : string
}

export default class EECT {  
  static get cart () : Array<EECTCartItem> {
    return JSON.parse(localStorage.getItem('EECT-Cart') || "[]") 
  }

  static set cart (value : Array<EECTCartItem> ) {
    localStorage.setItem('EECT-Cart', JSON.stringify(value))
  } 

  static addToCart(name : string, category : string) : void {
    let cart = this.cart
    
    if (!cart.find(ci => ci.name === name)) {
      cart.push({ name, category, quantity: 1 })
      
      this.cart = cart
      
      let ecommEvent = {
        event: 'addToCart',
        ecommerce: {
          add: {
            products: [{
              name: name,
              category: category,
              quantity: 1,
            }]
          }
        }
      }

      DelayedDataLayer.push(ecommEvent)
    }
  }

  static emptyCart() : void {
    localStorage.setItem('EECT-Cart', "[]")
  }
  
  static checkout (step : number, reactivation: boolean = false) : void { 
    let cart = this.cart

    if (cart.length === 0) {
      cart.push({
        name: "(not set)",
        category: "(not set)",
        quantity: 1,
      })
    }

    let ecommEvent = {
      event: 'checkout',
      ecommerce: {
        checkout: {
          actionField: { step: step },
          products: cart
        }
      }
    }

    if (reactivation) {
      ecommEvent["checkout_product"] = "reactivation"
    }

    window.dataLayer.push(ecommEvent)
  }

  static clickPayWithExistingMethod() {
    let event = {
      event: "cta_click",
      click_type: "checkout_1click_activation",
      checkout_product: "reactivation"
    }

    DelayedDataLayer.push(event)
  }

  
  static impressions (products : Array<EECTProduct>) {
    let ecommEvent = {
      event: 'productImpression',
      ecommerce: {
        impressions: products
      }
    }

    window.dataLayer.push(ecommEvent)
  }

  static click (name : string, category : string, list : string) : void {
    let ecommEvent = {
      event: 'productClick',
      ecommerce: {
        click: {
          actionField: { list },
          products: [{ name, category }]
        }
      }
    }

    DelayedDataLayer.push(ecommEvent)
  }

  static details (name : string, category : string, list : string) : void {
    let ecommEvent = {
      event: 'productDetailView',
      ecommerce: {
        detail: {
          actionField: { list },
          products: [{ name, category }]
        }
      }
    }

    window.dataLayer.push(ecommEvent)
  }

}